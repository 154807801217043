<template>
  <q-uploader
    :factory="uploadFactory"
    :label="$attrs.label"
    accept="image/*"
    auto-upload
    flat
    hide-upload-btn
  >
    <template v-slot:list="scope">
      <div class="flex flex-center">
        <q-img
          :src="setFormatFile($props.modelValue)"
          class="flex-center"
          fit="contain"
          spinner-color="white"
          style="max-width: 300px; height: 150px;"
        >
          <template v-slot:error>
            <div
              class="absolute-full flex flex-center bg-negative text-white rounded-borders"
              style="opacity: 0.5">
              <q-icon name="warning" style="font-size: 4rem;"/>
            </div>
          </template>
        </q-img>
        <div v-if="$props.modelValue" class="absolute-bottom text-subtitle3 text-center q-ma-xs">
          <a :href="setFormatFile($props.modelValue)"
             style="color: #ddd"
             target="_blank">Открыть</a>
        </div>
      </div>
    </template>
  </q-uploader>
</template>

<script>
import {
  defineComponent,
} from 'vue';

export default defineComponent({
  props: ['modelValue', 'uploadMethod', 'error'],
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const setFormatFile = (value) => {
      if (/\.(?:png)$|\.(?:jpeg)$|\.(?:jpg)$/.test(value)) {
        return value;
      }
      return `${value}.png`;
    };

    return {
      setFormatFile,
      uploadFactory: async (files) => {
        try {
          const { data } = await props.uploadMethod(files[0]);
          emit('update:modelValue', data.url);
        } catch (e) {
          if (typeof props.error === 'function') {
            props.error(e);
          }
          console.error(e);
        }
      },
    };
  },
});
</script>

<style>

</style>
