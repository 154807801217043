<template>
  <q-page class="q-pa-sm">
    <div class="row">
      <div class="col-12">
        <q-item>
          <q-input v-model="entity.state.banners.editItem.link" class="full-width" clearable dense label="Ссылка"
                   outlined/>
        </q-item>
      </div>
      <!--			<div class="col-12">-->
      <!--				<q-item>-->
      <!--					<q-input v-model="entity.state.banners.editItem.comment" type="textarea" class="full-width" clearable dense label="Комментарий" outlined />-->
      <!--				</q-item>-->
      <!--			</div>-->
      <!--			<div class="col-12">-->
      <!--				<q-item>-->
      <!--					<q-input v-model="entity.state.banners.editItem.rating" class="full-width" dense label="Рейтинг" outlined max="5" min="1"-->
      <!--						type="number">-->
      <!--					</q-input>-->
      <!--				</q-item>-->
      <!--			</div>-->
      <div class="col-12">
        <q-item>
          <image-uploader v-if="entityId" v-model="entity.state.banners.editItem.image"
                          :uploadMethod="file => saveImage(file)"
                          class="full-width"
                          label="Изображение для баннера (PNG/JPEG)"/>
        </q-item>
      </div>
    </div>
    <div class="q-pa-md">

      <q-btn class="q-mr-md" color="primary" icon="save" label="Сохранить" rounded @click="saveBannerEntity"/>

      <q-btn class="q-mr-md" icon="cancel" label="Назад" rounded @click="backPage"/>
    </div>
  </q-page>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { Notify } from 'quasar';
import { useStore } from 'vuex';
import { BannerApi } from '@/api/apis/banner-api.ts';
import ImageUploader from '@/components/forms/imageUploader';
import { Banner } from '@/entities/banner-entity';
import store from '@/store';

export default defineComponent({

  components: {
    ImageUploader,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    onMounted(getBannerById);

    const entityId = ref(route.params.id);
    const entity = useStore();
    const loading = ref(false);

    async function getBannerById() {
      if (entityId.value) {
        const response = await new BannerApi().bannerGetItems();
        store.dispatch('updateBanners', response.data);
        const [currentBanner] = store.state.banners.data.filter((el) => (el.id === Number.parseInt(entityId.value)));
        entity.dispatch('editBannerItemUpdate', currentBanner);
      }
    }

    async function saveBannerEntity() {
      loading.value = true;
      const banner = new Banner(
        entity.state.banners.editItem.link,
        entity.state.banners.editItem.image,
        entity.state.banners.editItem.id || entityId?.value,
        entity.state.banners.editItem.position,
      );
      try {
        if (banner.id) {
          await new BannerApi().bannerUpdate({ ...banner, id: undefined }, entityId.value);
        } else {
          const { data } = await new BannerApi().bannerCreate(banner);
          entityId.value = data.id;
        }

        Notify.create({
          type: 'positive',
          message: 'Сохранено',
        });
      } catch (e) {
        console.error(e);
      } finally {
        loading.value = false;
      }
    }

    async function saveImage(file) {
      try {
        loading.value = true;
        const response = await new BannerApi().bannerUploadImage(file, entityId.value || 1);
        entity.dispatch('editBannerItemUpdate', new Banner(
          entity.state.banners.editItem.link,
            response.data?.url,
            entity.state.banners.editItem.id || entityId?.value,
            entity.state.banners.editItem.position,
        ));
        if (response.status === 201) {
          Notify.create({
            type: 'positive',
            message: 'Изображение сохранено',
          });
        }
        return response;
      } catch (e) {
        console.error(e);
      } finally {
        loading.value = false;
      }
    }

    const backPage = () => {
      router.back();
      entity.dispatch('editBannerItemUpdate', null);
    };
    return {
      entity,
      BannerApi,
      loading,
      backPage,
      saveBannerEntity,
      entityId,
      saveImage,
    };
  },
});
</script>
<style></style>
