import { BaseAPI } from '../base';
import { UpdateCreateBannerDto } from '../models';

export class BannerApi extends BaseAPI {
  readonly localVarPath: string = '/api/banner';

  public bannerGetItems() {
    return this.axiosParamCreator({
      methodApi: 'GET',
      urlMethod: this.localVarPath,
    });
  }

  public bannerCreate(body: UpdateCreateBannerDto) {
    return this.axiosParamCreator({
      methodApi: 'POST',
      urlMethod: `${this.localVarPath}/create`,
      body,
    });
  }

  public bannerUpdate(body: UpdateCreateBannerDto, id: number) {
    return this.axiosParamCreator({
      methodApi: 'PUT',
      urlMethod: `${this.localVarPath}/${id}`,
      body,
    });
  }

  public deleteBanner(id: number) {
    return this.axiosParamCreator({
      methodApi: 'DELETE',
      urlMethod: `${this.localVarPath}/${id}`,
    });
  }

  public updatePositionBanner(body: any) {
    return this.axiosParamCreator({
      methodApi: 'POST',
      urlMethod: `${this.localVarPath}/positions`,
      body,
    });
  }

  public bannerUploadImage(image: string, id: number) {
    return this.axiosParamCreator({
      methodApi: 'POST',
      urlMethod: `${this.localVarPath}/${id}/image`,
      image,
    });
  }
}
